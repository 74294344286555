@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 24px 24px; */
  position: relative;

  @media (--viewportMedium) {
    /* margin: 24px 0; */
  }
}
