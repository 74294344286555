.root {
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}

.info {
  margin: 0;
  padding: 0 0 3px 0;
  color: var(--colorGrey300);
  font-size: 14px;
  & a {
    color: var(--colorGrey300);
    text-decoration: underline;
  }
}
